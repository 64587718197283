import { h, resolveComponent } from 'vue'
import { createRouter } from 'vue-router'
import { createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import('@/views/users/Users.vue'),
      },
      {
        path: '/users/BanedNationalNumbers',
        name: 'BanedNationalNumbers',
        component: () => import('@/views/users/BanedNationalNumbers.vue'),
      },
      {
        path: '/users/BanedCardNumbers',
        name: 'BanedCardNumbers',
        component: () => import('@/views/users/BanedCardNumbers.vue'),
      },
      {
        path: '/users/:id',
        name: 'UserDetail',
        component: () => import('@/views/users/UserDetail.vue'),
        props: true,
      },
      {
        path: '/user-search',
        name: 'UserSearch',
        component: () => import('@/views/user-search/UserSearch.vue'),
      },
      {
        path: '/operators',
        name: 'Operators',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/operators/list',
        children: [
          {
            path: '/operators/list',
            name: 'OperatorList',
            component: () => import('@/views/operators/OperatorList.vue'),
            props: true,
          },
          {
            path: '/operators/detail/:id',
            name: 'OperatorDetail',
            component: () => import('@/views/operators/OperatorDetail.vue'),
            props: true,
          },
          {
            path: '/operators/edit/:id',
            name: 'OperatorEdit',
            component: () => import('@/views/operators/OperatorEdit.vue'),
            props: true,
          },
          {
            path: '/operators/create',
            name: 'OperatorCreate',
            component: () => import('@/views/operators/OperatorCreate.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/kyc',
        name: 'KYCList',
        component: () => import('@/views/kyc/KYCList.vue'),
      },
      {
        path: '/kyc-card',
        name: 'KYCCardList',
        component: () => import('@/views/kyc-card/KYCCardList.vue'),
      },
      {
        path: '/kyc-aban-link',
        name: 'KYCAbanLink',
        component: () => import('@/views/aban-link/KYCAbanLink.vue'),
      },

      {
        path: '/accounting/irt/deposit',
        name: 'IRTDeposit',
        component: () => import('@/views/accounting/irt/IRTDeposit.vue'),
        props: true,
      },
      {
        path: '/accounting/irt/withdrawal',
        name: 'IRTWithdrawal',
        component: () => import('@/views/accounting/irt/IRTWithdrawal.vue'),
        props: true,
      },
      {
        path: '/accounting/irt/direct-debit',
        name: 'IrtDirectDebit',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/accounting/irt/direct-debit/contracts',
        children: [
          {
            path: '/accounting/irt/direct-debit/contracts',
            name: 'IrtDirectDebitContracts',
            component: () =>
              import('@/views/accounting/irt/DirectDebit/Contracts.vue'),
            props: true,
          },
          {
            path: '/accounting/irt/direct-debit/banks',
            name: 'DirectDebitBanks',
            component: () =>
              import('@/views/accounting/irt/DirectDebit/Banks.vue'),
            props: true,
          },
          {
            path: '/accounting/irt/direct-debit/banks/edit/:id',
            name: 'EditDirectDebitBank',
            component: () =>
              import('@/views/accounting/irt/DirectDebit/EditBank.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/accounting/crypto/deposit',
        name: 'CryptoDeposit',
        component: () => import('@/views/accounting/crypto/CryptoDeposit.vue'),
        props: true,
      },
      {
        path: '/accounting/crypto/withdrawal',
        name: 'CryptoWithdrawal',
        component: () =>
          import('@/views/accounting/crypto/CryptoWithdrawal.vue'),
        props: true,
      },
      {
        path: '/chest',
        name: 'Chest',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/chest/list',
        children: [
          {
            path: '/chest/list',
            name: 'ChestList',
            component: () => import('@/views/chests/ChestList.vue'),
            props: true,
          },
          {
            path: '/chest/detail/:id',
            name: 'ChestDetail',
            component: () => import('@/views/chests/ChestDetail.vue'),
            props: true,
          },
          {
            path: '/chest/create',
            name: 'ChestCreate',
            component: () => import('@/views/chests/ChestCreate.vue'),
            props: true,
          },
          {
            path: '/chest/edit/:id',
            name: 'ChestEdit',
            component: () => import('@/views/chests/ChestEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/marketing',
        name: 'Marketing',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/marketing/dashboard',
        children: [
          {
            path: '/marketing/dashboard',
            name: 'Marketing',
            component: () => import('@/views/marketing/Marketing.vue'),
            props: true,
          },
          {
            path: '/marketing/sourceReport',
            name: 'SourceReport',
            component: () => import('@/views/marketing/SourceReport.vue'),
            props: true,
          },
        ],
      },

      {
        path: '/referral/',
        name: 'ReferralList',
        component: () => import('@/views/referral/ReferalList.vue'),
      },
      {
        path: '/referral/edit/:id',
        name: 'EditList',
        component: () => import('@/views/referral/EditList.vue'),
        props: true,
      },

      {
        path: '/coins',
        name: 'Coins',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/coins/list',
        children: [
          {
            path: '/coins/list',
            name: 'CoinList',
            component: () => import('@/views/exchange/coins/CoinList.vue'),
            props: true,
          },
          {
            path: '/coins/detail/:id',
            name: 'CoinDetail',
            component: () => import('@/views/exchange/coins/CoinDetail.vue'),
            props: true,
          },
          {
            path: '/coins/create',
            name: 'CoinCreate',
            component: () => import('@/views/exchange/coins/CoinCreate.vue'),
            props: true,
          },
          {
            path: '/coins/edit/:id',
            name: 'CoinEdit',
            component: () => import('@/views/exchange/coins/CoinEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/networks',
        name: 'Networks',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/networks/list',
        children: [
          {
            path: '/networks/list',
            name: 'NetworkList',
            component: () =>
              import('@/views/exchange/networks/NetworkList.vue'),
            props: true,
          },
          {
            path: '/networks/detail/:id',
            name: 'NetworkDetail',
            component: () =>
              import('@/views/exchange/networks/NetworkDetail.vue'),
            props: true,
          },
          {
            path: '/networks/create',
            name: 'NetworkCreate',
            component: () =>
              import('@/views/exchange/networks/NetworkCreate.vue'),
            props: true,
          },
          {
            path: '/networks/edit/:id',
            name: 'NetworkEdit',
            component: () =>
              import('@/views/exchange/networks/NetworkEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/box',
        name: 'MoneyBox',
        component: () => import('@/views/exchange/moneyBox/MoneyBox.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/settings/Settings.vue'),
      },
      {
        path: '/P2PSettings',
        name: 'P2PSettings',
        component: () => import('@/views/settings/p2p/p2pSetting'),
      },
      {
        path: '/FeeSettings',
        name: 'FeeSettings',
        component: () => import('@/views/settings/p2p/feeSetting'),
      },
      {
        path: '/wallets',
        name: 'Wallets',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/wallets/list',
        children: [
          {
            path: '/wallets/list',
            name: 'WalletList',
            component: () => import('@/views/exchange/wallets/WalletList.vue'),
            props: true,
          },
          {
            path: '/wallets/detail/:id',
            name: 'WalletDetail',
            component: () =>
              import('@/views/exchange/wallets/WalletDetail.vue'),
            props: true,
          },
          {
            path: '/wallets/create',
            name: 'WalletCreate',
            component: () =>
              import('@/views/exchange/wallets/WalletCreate.vue'),
            props: true,
          },
          {
            path: '/wallets/edit/:id',
            name: 'WalletEdit',
            component: () => import('@/views/exchange/wallets/WalletEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/manual-order',
        name: 'ManualOrder',
        component: () =>
          import('@/views/exchange/manual-order/ManualOrder.vue'),
        props: true,
      },
      {
        path: '/exchanges',
        name: 'Exchanges',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/exchanges/list',
        children: [
          {
            path: '/exchanges/list',
            name: 'ExchangeList',
            component: () =>
              import('@/views/exchange/exchanges/ExchangeList.vue'),
            props: true,
          },
          {
            path: '/exchanges/detail/:id',
            name: 'ExchangeDetail',
            component: () =>
              import('@/views/exchange/exchanges/ExchangeDetail.vue'),
            props: true,
          },
          {
            path: '/exchanges/create',
            name: 'ExchangeCreate',
            component: () =>
              import('@/views/exchange/exchanges/ExchangeCreate.vue'),
            props: true,
          },
          {
            path: '/exchanges/edit/:id',
            name: 'ExchangeEdit',
            component: () =>
              import('@/views/exchange/exchanges/ExchangeEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/public-messages',
        name: 'PublicMessages',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/public-messages/list',
        children: [
          {
            path: '/public-messages/list',
            name: 'PublicMessageList',
            component: () =>
              import('@/views/messages/publicMessages/PublicMessageList.vue'),
            props: true,
          },
          {
            path: '/public-messages/detail/:id',
            name: 'PublicMessageDetail',
            component: () =>
              import('@/views/messages/publicMessages/PublicMessageDetail.vue'),
            props: true,
          },
          {
            path: '/public-messages/create',
            name: 'PublicMessageCreate',
            component: () =>
              import('@/views/messages/publicMessages/PublicMessageCreate.vue'),
            props: true,
          },
          {
            path: '/public-messages/edit/:id',
            name: 'PublicMessageEdit',
            component: () =>
              import('@/views/messages/publicMessages/PublicMessageEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/pop-ups',
        name: 'PopUps',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/pop-ups/list',
        children: [
          {
            path: '/pop-ups/list',
            name: 'PopUpList',
            component: () => import('@/views/messages/popUps/PopUpList.vue'),
            props: true,
          },
          {
            path: '/pop-ups/create',
            name: 'PopUpCreate',
            component: () => import('@/views/messages/popUps/PopUpCreate.vue'),
            props: true,
          },
          {
            path: '/pop-ups/edit/:id',
            name: 'PopUpEdit',
            component: () => import('@/views/messages/popUps/PopUpEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/accounting-reports',
        name: 'AccountingReports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        children: [
          {
            path: '/accounting-reports/definite-assets-report',
            name: 'AssetManagementDefiniteReport',
            component: () =>
              import(
                '@/views/asset-management/definite-assets/DefiniteAssetsReport.vue'
              ),
            props: true,
          },
          {
            path: '/accounting-reports/financial-document',
            name: 'FinancialDocument',
            component: () =>
              import(
                '@/views/accountingReports/FinancialDocument/FinancialDocument'
              ),
            props: true,
          },

          {
            path: '/accounting-reports/usdt-transactions',
            name: 'UsdtTransactions',
            component: () =>
              import(
                '@/views/accountingReports/UsdtTransactions/UsdtTransactions'
              ),
            props: true,
          },
          {
            path: '/accounting-reports/usdt-daily-transactions',
            name: 'UsdtDailyTransactions',
            component: () =>
              import(
                '@/views/accountingReports/UsdtDailyTransactions/UsdtDailyTransactions'
              ),
            props: true,
          },
        ],
      },
      {
        path: '/reports',
        name: 'Reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/reports/users',
        children: [
          {
            path: '/reports/users',
            name: 'UsersReports',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            props: true,
            redirect: '/reports/users/list',
            children: [
              {
                path: '/reports/users/list',
                name: 'UsersReportsList',
                component: () => import('@/views/reports/users/UserList.vue'),
                props: true,
              },
              {
                path: '/reports/users/detail/:id',
                name: 'UserReportsDetail',
                component: () => import('@/views/reports/users/UserDetail.vue'),
                props: true,
              },
            ],
          },
          {
            path: '/reports/admins',
            name: 'AdminReports',
            component: () => import('@/views/reports/AdminReports.vue'),
            props: true,
          },
          {
            path: '/reports/tether',
            name: 'TetherReport',
            component: () => import('@/views/reports/TetherReport.vue'),
            props: true,
          },
          {
            path: '/reports/fees',
            name: 'FeesReport',
            component: () => import('@/views/reports/FeesReport.vue'),
            props: true,
          },
          {
            path: '/reports/profit',
            name: 'ProfitReport',
            component: () => import('@/views/reports/ProfitReport.vue'),
            props: true,
          },
          {
            path: '/reports/transactions/fiat',
            name: 'FiatTransactionReport',
            component: () =>
              import('@/views/reports/transactions/FiatTransactionReport.vue'),
            props: true,
          },
          {
            path: '/reports/transactions/crypto',
            name: 'CryptoTransactionReport',
            component: () =>
              import(
                '@/views/reports/transactions/CryptoTransactionReport.vue'
              ),
            props: true,
          },
          {
            path: '/reports/transactions/trades',
            name: '',
            component: () => import('@/views/reports/transactions/trades.vue'),
            props: true,
          },

          {
            path: '/reports/market',
            name: 'MarketReport',
            component: () => import('@/views/reports/MarketRport.vue'),
            props: true,
          },
          {
            path: '/reports/extreme-users',
            name: 'ExtremeUsersReport',
            component: () => import('@/views/reports/ExtremeUsersReport.vue'),
            props: true,
          },
          {
            path: '/reports/list',
            name: 'ReportList',
            component: () => import('@/views/reports/list/ReportList.vue'),
          },
          {
            path: '/reports/crypto-withdrawal-fee-profit',
            name: 'CryptoWithdrawalFeeReport',
            component: () =>
              import('@/views/reports/CryptoWithdrawalFeeProfitReport.vue'),
            props: true,
          },
          {
            path: '/reports/costs',
            name: 'CostsReport',
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            props: true,
            children: [
              {
                path: '/reports/costs/referral',
                name: 'ReferralCostsReport',
                component: () => import('@/views/reports/costs/Referral.vue'),
                props: true,
              },
              {
                path: '/reports/costs/exchange-fee',
                name: 'ExchangeFeeReport',
                component: () =>
                  import('@/views/reports/costs/exchange-fee/ExchangeFee.vue'),
                props: true,
              },
              {
                path: '/reports/costs/campaign',
                name: 'CampaignReport',
                component: () =>
                  import('@/views/reports/costs/campaign/Campaign.vue'),
                props: true,
              },
            ],
          },
          {
            path: '/reports/blocker-routines',
            name: 'BlockerRoutinesReport',
            component: () =>
              import('@/views/reports/BlockerRoutinesReport.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/private-messages',
        name: 'PrivateMessages',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/private-messages/list',
        children: [
          {
            path: '/private-messages/list',
            name: 'PrivateMessageList',
            component: () =>
              import('@/views/messages/privateMessages/PrivateMessageList.vue'),
            props: true,
          },
          {
            path: '/private-messages/detail/:id',
            name: 'PrivateMessageDetail',
            component: () =>
              import(
                '@/views/messages/privateMessages/PrivateMessageDetail.vue'
              ),
            props: true,
          },
          {
            path: '/private-messages/create',
            name: 'PrivateMessageCreate',
            component: () =>
              import(
                '@/views/messages/privateMessages/PrivateMessageCreate.vue'
              ),
            props: true,
          },
          {
            path: '/private-messages/edit/:id',
            name: 'PrivateMessageEdit',
            component: () =>
              import('@/views/messages/privateMessages/PrivateMessageEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/bulk-messages',
        name: 'BulkMessages',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/bulk-messages/list',
        children: [
          {
            path: '/bulk-messages/list',
            name: 'BulkMessageList',
            component: () =>
              import('@/views/messages/bulkMessages/BulkMessageList.vue'),
            props: true,
          },
          {
            path: '/bulk-messages/create',
            name: 'BulkMessageCreate',
            component: () =>
              import('@/views/messages/bulkMessages/BulkMessageCreate.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/contracts',
        name: 'Contracts',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        props: true,
        redirect: '/contracts/list',
        children: [
          {
            path: '/contracts/list',
            name: 'ContractList',
            component: () =>
              import('@/views/contracts/contracts/ContractList.vue'),
            props: true,
          },
          {
            path: '/contract/create',
            name: 'ContractCreate',
            component: () =>
              import('@/views/contracts/contracts/ContractCreate.vue'),
            props: true,
          },
          {
            path: '/contract/detail/:id',
            name: 'ContractView',
            component: () =>
              import('@/views/contracts/contracts/ContractView.vue'),
            props: true,
          },
          {
            path: '/contract/edit/:id',
            name: 'ContractEdit',
            component: () =>
              import('@/views/contracts/contracts/ContractEdit.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/double-check',
        name: 'doubleCheck',
        component: () => import('@/views/double-check/DoubleCheck.vue'),
        props: true,
      },
      {
        path: '/withdrawals',
        name: 'WithdrawalList',
        component: () => import('@/views/withdrawals/WithdrawalList.vue'),
        props: true,
      },
      {
        path: '/deposits',
        name: 'DepositList',
        component: () => import('@/views/deposits/DepositList.vue'),
        props: true,
      },
      {
        path: '/requests',
        name: 'RequestList',
        component: () => import('@/views/requests/RequestList.vue'),
        props: true,
      },
      {
        path: '/wallets-supply',
        name: 'WalletsSupply',
        component: () => import('@/views/wallets-supply/WalletSupplyList.vue'),
        props: true,
      },
      {
        path: '/wallets-transfer',
        name: 'WalletsTransfer',
        component: () =>
          import('@/views/wallets/wallets-transfer/WalletTransfer.vue'),
        props: true,
      },
      {
        path: '/automatic-transfer',
        name: 'AutomaticTransfer',
        component: () =>
          import('@/views/wallets/automatic-transfer/AutomaticTransfer.vue'),
        props: true,
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('@/views/orders/otc/OrderList.vue'),
        props: true,
      },
      {
        path: '/order-tracking',
        name: 'OrderTracking',
        component: () => import('@/views/orders/otc/OrderTracking.vue'),
        props: true,
      },

      {
        path: '/p2p-order-tracking',
        name: 'P2POrderTracking',
        component: () => import('@/views/orders/p2p/P2POrderList.vue'),
        props: true,
      },

      {
        path: '/p2p-trading',
        name: 'P2PTrading',
        component: () => import('@/views/orders/p2p/P2PTrade.vue'),
        props: true,
      },

      {
        path: '/OTC-profit',
        name: 'OTCProfit',
        // component: () => import('@/views/orders/p2p/P2POrderList.vue'),
        props: true,
      },

      {
        path: '/P2P-profit',
        name: 'P2PProfit',
        component: () => import('@/views/reports/profit/p2p.vue'),
        props: true,
      },

      {
        path: '/bot-reports',
        name: 'BotReports',
        component: () => import('@/views/bot/reports.vue'),
        props: true,
      },

      {
        path: '/bot-setting',
        name: 'BotSetting',
        component: () => import('@/views/bot/setting.vue'),
        props: true,
      },
      {
        path: '/personal-wallets',
        name: 'PersonalWallets',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/personal-wallets/list',
            name: 'PersonalWalletList',
            component: () =>
              import('@/views/personal-wallets/PersonalWalletList.vue'),
            props: true,
          },
          {
            path: '/personal-wallets/deposits',
            name: 'PersonalWalletDeposits',
            component: () =>
              import('@/views/personal-wallets/deposits/DepositList.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/reports/manualTransactions',
        name: 'manualTransactions',
        component: () =>
          import('@/views/reports/manualTransactions/manualTransactions.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
      {
        path: '/test',
        name: 'Test',
        component: () => import('@/components/Test.vue'),
      },
      {
        path: '/user-id-convert',
        name: 'UserIdConvert',
        component: () => import('@/views/user-id-convert/UserIdConvert.vue'),
      },
      {
        path: '/loan/information',
        name: 'LoanInformation',
        component: () => import('@/views/loan/information/Information.vue'),
      },
      {
        path: '/loan/installments',
        name: 'LoanInstallments',
        component: () => import('@/views/loan/installments/Installments.vue'),
      },
      {
        path: '/loan/loans',
        name: 'LoanLoans',
        component: () => import('@/views/loan/loans/Loans.vue'),
      },
      // {
      //   path: '/aban-card/cards',
      //   name: 'AbanCardCards',
      //   component: () => import('@/views/aban-card/cards/Cards.vue'),
      // },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/authentication/Authentication'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
